<template>
    <div class="page-explore hero-wrapper">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Explore Published Datasets</h1>
            </div>
        </div>

        <div class="columns is-multiline">
            <div class="column is-12 box metadataFilter">
                <p class="primary-text has-text-weight-medium mb-4">Metadata Filter</p>

                <div class="columns is-multiiline is-centered">
                    <MetadataDropdownFilter v-bind:dropdownOptions="metadataFilter['engineeringDisciplines']"
                        title="Engineering Discipline" v-on:filterSelection="engineeringDisciplineValue = $event"
                        v-on:change="filterProjects();" />

                    <MetadataDropdownFilter v-bind:dropdownOptions="metadataFilter['licenses']" title="License"
                        v-on:filterSelection="licenseValue = $event" v-on:change="filterProjects();" />
                </div>

                <div class="columns is-multiiline is-centered">
                    <MetadataDropdownFilter v-bind:dropdownOptions="metadataFilter['experimentTypes']"
                        title="Experiment Type" v-on:filterSelection="experimentTypeValue = $event"
                        v-on:change="filterProjects();" />

                    <MetadataDropdownFilter v-bind:dropdownOptions="metadataFilter['experimentScales']"
                        title="Experiment Scale" v-on:filterSelection="experimentScaleValue = $event"
                        v-on:change="filterProjects();" v-model="experimentScaleValue" />
                </div>
            </div>

            <hr>
            <div class="column is-12 box taxonomyForm">
                <p class="primary-text has-text-weight-medium mb-4">Taxonomy</p>

                <div class="columns is-multiiline is-centered">
                    <DropdownOption v-bind:dropdownOptions="specimenTypes"
                        v-on:selectionChanged="specimenTypeValue = $event" title="Specimen Type"
                        v-on:change="getSpecimenTypeChildren(specimenTypeValue.selectionID); queryProjects(taxonomyQuery);" />

                    <DropdownOption v-bind:dropdownOptions="specimenSubTypes"
                        v-on:selectionChanged="specimenSubTypeValue = $event" title="Specimen Sub-Type"
                        v-on:change="queryProjects(taxonomyQuery)" />
                </div>

                <div class="columns is-multiiline is-centered">
                    <DropdownOption v-bind:dropdownOptions="materialTypes"
                        v-on:selectionChanged="materialTypeValue = $event" title="Material Type"
                        v-on:change="getMaterialTypeChildren(materialTypeValue.selectionID); queryProjects(taxonomyQuery);" />

                    <DropdownOption v-bind:dropdownOptions="materialTechnologies"
                        v-on:selectionChanged="materialTechnologyValue = $event" title="Material Technology"
                        v-on:change="queryProjects(taxonomyQuery)" />
                </div>
                <hr>
            </div>
        </div>

        <div class="columns is-multiline">
            <div class="column is-4">
                <strong>Taxonomy String</strong>
                <div class="field">
                    <label class="taxonomy-label" v-if="specimenTypeValue.name != 'Any'">{{ specimenTypeValue.name
                    }}+</label>
                    <label class="taxonomy-label" v-if="specimenSubTypeValue.name != 'Any'">{{ specimenSubTypeValue.name
                    }}+</label>
                    <label class="taxonomy-label" v-if="materialTypeValue.name != 'Any'">{{ materialTypeValue.name
                    }}+</label>
                    <label class="taxonomy-label" v-if="materialTechnologyValue.name != 'Any'">{{
                        materialTechnologyValue.name }}+</label>
                </div>
            </div>

            <div class="column is-4">
                <strong>Number of Available Datasets / Total Number of Datasets Available</strong>
                <div>
                    {{ filteredProjects.length }} / {{ totalProjects }}
                </div>
            </div>

            <div class="column is-4">
                <button @click="openExploreResults()" class="button primary-button mt-4 mr-4" style="color: #2B2B2B">explore-results/{{ taxonomyQuery }}</button>
            </div>

        </div>

        <hr>
        <div class="columns is-multiline">
            <div class="column is-2">
                <div class="column is-12" style="display: none">
                    <h1 class="title">Displaying {{ visibleProjects.length }} out of {{ filteredProjects.length }} Datasets
                    </h1>
                </div>

                <div class="columns is-multiiline">
                    <div class="column is-one-quarter mr-2">
                        <p>Show</p>
                    </div>
                    <div class="column">
                        <div class="field">
                            <div class="select is-fullwidth">
                                <select v-model="pageSize" name="option-selection" @change="changeNumberOfDatasetsPerPage($event)">
                                    <option v-for="dropdownOption in pageSizeList" :key="dropdownOption" :value="dropdownOption">
                                        {{ dropdownOption }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-12 box" v-if="!isMobile()">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Dataset Title</th>
                            <th>Dataset PI(s)</th>
                            <th>Dataset Facility</th>
                            <th>Dates of Experiment</th>
                        </tr>
                    </thead>
                    <GenericProjectItem v-for="project in visibleProjects" v-bind:currentPage="currentPage"
                        v-bind:key="project.id" v-bind:project="project" v-bind:page="'home'" />
                    <tbody>

                    </tbody>
                </table>
            </div>

            <ProjectBox v-else v-for="project in visibleProjects" v-bind:currentPage="currentPage" v-bind:key="project.id"
                v-bind:project="project" />

            <Pagination v-bind:projects="filteredProjects" v-on:page:update="updatePage" v-bind:currentPage="currentPage"
                v-bind:pageSize="pageSize" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { useHead } from '@vueuse/head'

import { isMobile, setLocalStorage, getLocalStorage } from '@/assets/methods.js'
import DropdownOption from '@/components/DropdownOptions'
import MetadataDropdownFilter from '@/components/MetadataDropdownFilter'
import TaxonomySelection from '@/components/TaxonomySelection'
import GenericProjectItem from '@/components/GenericProjectItem'
import ProjectBox from '@/components/ProjectBox'
import Pagination from '@/components/Pagination'

export default {
    name: "Explore",
    components: {
        DropdownOption,
        TaxonomySelection,
        GenericProjectItem,
        ProjectBox,
        Pagination,
        MetadataDropdownFilter,
    },
    data() {
        return {
            // all datasets/projects
            totalProjects: 0,
            projects: [],
            filteredProjects: [],
            currentPage: 0,
            pageSize: 25,
            visibleProjects: [],
            pageSizeList: [10, 25, 50, 100],

            // taxonomy
            specimenTypes: [],
            specimenSubTypes: [],
            materialTypes: [],
            materialTechnologies: [],

            // taxonomy query
            taxonomyQuery: ['Any'],

            specimenTypeValue: { 'name': 'Any' },
            specimenSubTypeValue: { 'name': 'Any' },

            materialTypeValue: { 'name': 'Any' },
            materialTechnologyValue: { 'name': 'Any' },

            // metadata filter
            metadataFilter: {
                "engineeringDisciplines": [],
                "experimentTypes": [],
                "licenses": [],
                "experimentScales": [],
            },
            engineeringDisciplineValue: 'Any',
            experimentTypeValue: 'Any',
            licenseValue: 'Any',
            experimentScaleValue: 'Any',

            // archived
            materialProperties: [],
            systems: [],
            ductilities: [],
            materialPropertyValue: { 'name': 'Any' },
            systemValue: { 'name': 'Any' },
            ductilityValue: { 'name': 'Any' },

        }
    },
    setup() {
        useHead({
            meta: [
                { name: 'description', content: "Explore published experimental datasets" }
            ]
        })
    },
    mounted() {
        document.title = "Explore Datasets | " + this.$store.state.appName

        this.getSpecimenTypes()
    },
    beforeMount() {
        this.queryProjects().then(() => {
            this.getTotalNumberOfDatasets()
        })
    },
    methods: {
        isMobile,
        getTotalNumberOfDatasets() {
            this.totalProjects = this.projects.length
        },
        changeNumberOfDatasetsPerPage(event) {
            this.pageSize = parseInt(event.target.value)

            this.updatePage(0)
        },
        updatePage(pageNumber) {
            this.currentPage = pageNumber;
            this.updateVisibleProjects();
        },
        updateVisibleProjects() {
            this.visibleProjects = this.filteredProjects.slice(
                this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize
            );

            // go back a page
            if (this.visibleProjects.length == 0 && this.currentPage > 0) {
                this.updatePage(this.currentPage - 1);
            }
        },
        async queryProjects() {
            this.$store.commit('setIsLoading', true)

            if (this.specimenTypeValue["name"] === "Any") {
                this.specimenSubTypeValue["name"] = "Any"
                this.materialTypeValue["name"] = "Any"
            }

            if (this.materialTypeValue["name"] === "Any") {
                this.materialTechnologyValue["name"] = "Any"
            }
            
            this.taxonomyQuery = [
                this.specimenTypeValue["name"],
                this.specimenSubTypeValue["name"],
                this.materialTypeValue["name"],
                this.materialTechnologyValue["name"],
            ];

            this.taxonomyQuery = this.taxonomyQuery.filter(function (val) { return val != 'Any'; }).join('/')
            
            var storage = getLocalStorage('datasets' + this.taxonomyQuery)
            
            if (storage !== null) {
                this.projects = storage
            } else {
                await axios
                    .post('/api/v1/datasets/explore-results/', { 'tax': this.taxonomyQuery })
                    .then(response => {
                        this.projects = response.data

                        setLocalStorage('datasets' + this.taxonomyQuery, this.projects)
                        
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }

            // Update URL
            const newURL = new URL(window.location.href)
            newURL.searchParams.set('tax', this.taxonomyQuery)
            window.history.pushState({}, '', newURL.href)

            this.filteredProjects = this.projects
            this.$store.commit('setIsLoading', false)

            // get only visible datasets per page
            this.updateVisibleProjects()
            this.updateMetadataFilter()
            this.filterProjects()

        },
        async getSpecimenTypes() {

            var storage = getLocalStorage('specimenTypes')
            
            if (storage !== null) {
                this.specimenTypes = storage
                
            } else {
                await axios
                    .get(`/api/v1/taxonomy/get-specimen-types/`)
                    .then(response => {
                        this.specimenTypes = response.data

                        setLocalStorage('specimenTypes', this.specimenTypes)
                        
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        async getSpecimenTypeChildren(specimenTypeId) {
            
            if (specimenTypeId === "" | specimenTypeId === "Any") {
                this.materialTypes = []
                this.specimenSubTypes = []

            } else {

                var storage = getLocalStorage('specimenType' + specimenTypeId)
            
                if (storage !== null) {
                    this.materialTypes = storage.material_type
                    this.specimenSubTypes = storage.specimen_sub_type
                    
                }  else {
                    await axios
                        .get(`/api/v1/taxonomy/get-specimen-types/${specimenTypeId}/`)
                        .then(response => {
                            this.materialTypes = response.data.material_type
                            this.specimenSubTypes = response.data.specimen_sub_type

                            setLocalStorage('specimenType' + specimenTypeId, response.data)

                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            }
        },
        async getMaterialTypeChildren(materialTypeId) {
            if (materialTypeId === "" | materialTypeId === "Any") {
                this.materialTechnologies = []

            } else {
                var storage = getLocalStorage('materialType' + materialTypeId)
            
                if (storage !== null) {
                    this.materialTechnologies = storage
                    
                } else {
                    await axios
                        .get(`/api/v1/taxonomy/get-material-types/${materialTypeId}/`)
                        .then(response => {
                            this.materialTechnologies = response.data.technology

                            setLocalStorage('materialType' + materialTypeId, response.data.technology)

                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            }
        },
        updateMetadataFilter() {

            let engineeringDisciplines = []
            let licenses = []
            let experimentTypes = []
            let experimentScales = []

            this.projects.forEach(function (project) {
                project.engineering_discipline.forEach(function (discipline) {
                    if (engineeringDisciplines.indexOf(discipline.discipline) === -1) {
                        engineeringDisciplines.push(discipline.discipline)
                    }
                })
                project.license.forEach(function (license) {
                    if (licenses.indexOf(license.name) === -1) {
                        licenses.push(license.name)
                    }
                })
                project.experiment_type.forEach(function (experiment_type) {
                    if (experimentTypes.indexOf(experiment_type.name) === -1) {
                        experimentTypes.push(experiment_type.name)
                    }
                })
                project.experiment_scale.forEach(function (experiment_scale) {
                    if (experimentScales.indexOf(experiment_scale.name) === -1) {
                        experimentScales.push(experiment_scale.name)
                    }
                })
            })

            // update metadata filter
            this.metadataFilter.engineeringDisciplines = engineeringDisciplines.sort()
            this.metadataFilter.licenses = licenses.sort()
            this.metadataFilter.experimentTypes = experimentTypes.sort()
            this.metadataFilter.experimentScales = experimentScales.sort()

        },
        filterProjects() {

            this.filteredProjects = this.projects.filter(
                project =>
                    (project.engineering_discipline.some(engineering_discipline => this.engineeringDisciplineValue.includes(engineering_discipline.discipline) || this.engineeringDisciplineValue === "Any") || project.engineering_discipline.length === 0) &&

                    (project.license.some(license => this.licenseValue.includes(license.name) || this.licenseValue === "Any") || project.license.length === 0) &&

                    (project.experiment_type.some(experiment_type => this.experimentTypeValue.includes(experiment_type.name) || this.experimentTypeValue === "Any") || project.experiment_type.length === 0) &&

                    (project.experiment_scale.some(experiment_scale => this.experimentScaleValue.includes(experiment_scale.name) || this.experimentScaleValue === "Any") || project.experiment_scale.length === 0)
            )

            this.updatePage(0)
        },
        openExploreResults() {
            sessionStorage.setItem('exploredProjects', JSON.stringify(this.projects))
            sessionStorage.setItem('taxonomySearchKey', this.taxonomyQuery)

            this.$router.push({
                name: "exploreResults",
                query: { tax: this.taxonomyQuery },
            })
        },

        // archived
        async getMaterialTypes() {
            await axios
                .get(`/api/v1/taxonomy/get-material-types/`)
                .then(response => {
                    this.materialTypes = response.data

                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getMaterialTechnologiesProperties(materialTypeId) {
            if (materialTypeId === "" | materialTypeId === "Any") {
                this.materialTechnologies = []
                this.materialProperties = []

            } else {
                await axios
                    .get(`/api/v1/taxonomy/get-material-types/${materialTypeId}/`)
                    .then(response => {
                        this.materialTechnologies = response.data.technology
                        this.materialProperties = response.data.property
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        async getSystems() {
            await axios
                .get(`/api/v1/taxonomy/get-llr-systems/`)
                .then(response => {
                    this.systems = response.data

                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getDuctilities() {
            await axios
                .get(`/api/v1/taxonomy/get-system-ductility/`)
                .then(response => {
                    this.ductilities = response.data

                })
                .catch(error => {
                    console.log(error)
                })
        },
    }
}
</script>

<style scoped>
.field {
    border: 1px solid black;
}

.taxonomy-label {
    cursor: text;
}
</style>