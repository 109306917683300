<template>
    <div class="column is-6">
        <div class="box">
            <figure class="image mb-4">
                <img loading="lazy" v-bind:src="project.get_thumbnail" v-bind:alt="project.keywords" decoding="async" style="max-height:344px; aspect-ratio: 3/2;">
            </figure>

            <h3 class="is-size-5">{{ project.experiment_title }}</h3>
            <p class="is-size-10 has-text-grey" v-for="experiment_pi in project.experiment_pi" v-bind:key="experiment_pi.id"
                v-bind:experiment_pi="experiment_pi">

                <span class="has-text-link" @click="goTo(experiment_pi.get_absolute_url)" style="cursor: pointer">
                    {{ experiment_pi.first_name['0'] }}. {{ experiment_pi.last_name }}
                </span>
                
                <!--<router-link v-bind:to="experiment_pi.get_absolute_url">
                    <span>{{ experiment_pi.first_name }}</span> <span>{{ experiment_pi.last_name }}</span>
                </router-link> -->
            </p>

            <router-link v-bind:to="project.get_absolute_url" class="button is-dark mt-4">View details</router-link>

        </div>
    </div>
</template>


<script>
export default {
    name: 'ProjectBox',
    props: {
        project: Object
    },
    methods: {
        goTo(param) {
            console.log(param)
            this.$router.push({ path: param })
            .then(() => { this.$router.go() })
        }
    }
}
</script>


<style scoped>
.image {
    margin-top: -1.25rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
}
</style>